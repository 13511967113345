import { Track, URLTrack, PartialState } from "./Webamp";
// @ts-ignore
import llamaAudio from "url:../mp3/llama-2.91.mp3";

interface Config {
  initialTracks?: Track[];
  audioUrl?: string;
  skinUrl?: string;
  disableMarquee?: boolean;
  initialState?: PartialState;
}

const { hash } = window.location;
let config: Config = {};
if (hash) {
  try {
    config = JSON.parse(decodeURIComponent(hash).slice(1));
  } catch (e) {
    console.error("Failed to decode config from hash: ", hash);
  }
}

// Backwards compatibility with the old syntax
if (config.audioUrl && !config.initialTracks) {
  config.initialTracks = [{ url: config.audioUrl }];
}

export const SHOW_DESKTOP_ICONS = true;

if ("URLSearchParams" in window) {
  // const params = new URLSearchParams(location.search);
  // SHOW_DESKTOP_ICONS = Boolean(params.get("icons"));
}

export const skinUrl = config.skinUrl ?? null;

export const defaultInitialTracks: URLTrack[] = [
  {
    url: "https://stream.surradio.live/listen/main/sur_main.mp3",
    defaultName: "Radio Sur - Main Station",
    streamInfo: {
      bitrate: 320000,
      sampleRate: 44000,
      numberOfChannels: 2,
    },
  },
  {
    url: "https://stream.surradio.live/listen/happyhardcore/sur_happyhardcore.mp3",
    defaultName: "Radio Sur - Happy Hardcore/Breakcore Station",
    streamInfo: {
      bitrate: 320000,
      sampleRate: 44000,
      numberOfChannels: 2,
    },
  },
  {
    url: "https://stream.surradio.live/listen/techno/sur_techno.mp3",
    defaultName: "Radio Sur - Techno Station",
    streamInfo: {
      bitrate: 320000,
      sampleRate: 44000,
      numberOfChannels: 2,
    },
  },
  {
    url: "https://stream.surradio.live/listen/dubstep/sur_dubstep.mp3",
    defaultName: "Radio Sur - Dubstep Station",
    streamInfo: {
      bitrate: 320000,
      sampleRate: 44000,
      numberOfChannels: 2,
    },
  },
  {
    url: "https://stream.surradio.live/listen/dnb/sur_dnb.mp3",
    defaultName: "Radio Sur - Drum & Bass Station",
    streamInfo: {
      bitrate: 320000,
      sampleRate: 44000,
      numberOfChannels: 2,
    },
  },
];

export const initialTracks = config.initialTracks || defaultInitialTracks;

export const disableMarquee = config.disableMarquee || false;
export const initialState = config.initialState || undefined;
