import { WebampLazy } from "./Webamp";

const getShortname = (x: string) => x.match(/\/listen\/(.+)\//)?.[1];
const getArtwork = async (shortName: string) => {
  const response = await fetch(
    `https://stream.surradio.live/api/nowplaying/${shortName}`
  );
  const json = await response.json();
  const { art, title } = json.now_playing.song;
  return { art, title };
};

export default function enableMediaSession(webamp: WebampLazy) {
  if ("mediaSession" in navigator) {
    let title: string | null = null;
    let previous_title: string | null = null;
    let artist: string | null = null;

    setInterval(async () => {
      if (title == null || previous_title === title) {
        return;
      }

      const state = webamp.store.getState();
      if (state.playlist.currentTrack === null) {
        return;
      }
      const currentTrack = state.tracks[state.playlist.currentTrack];
      if (!currentTrack.url) {
        return;
      }
      const shortName = getShortname(currentTrack.url);

      let metadata = new MediaMetadata({
        title,
        artist: artist || undefined,
      });

      if (shortName) {
        const artwork = await getArtwork(shortName);
        if (title != artwork.title) {
          return;
        }
        metadata.artwork = [
          {
            src: artwork.art,
          },
        ];
      }

      console.log(metadata);
      previous_title = title;
      navigator.mediaSession.metadata = metadata;
    }, 1500);

    /* global MediaMetadata */
    webamp.onMetadataDidChange(async (up_artist, up_title, stationName) => {
      title = up_title;
      artist = up_artist;
    });

    webamp.onTrackDidChange((track) => {
      if (track == null) {
        return;
      }
      const {
        metaData: { title, artist, album, albumArtUrl },
      } = track;
      if (!title) {
        return;
      }
      // @ts-ignore TypeScript does not know about the Media Session API: https://github.com/Microsoft/TypeScript/issues/19473
      navigator.mediaSession.metadata = new MediaMetadata({
        title,
        artist: artist || undefined,
        album: album || undefined,
        artwork: albumArtUrl
          ? [
              {
                src: albumArtUrl,
                // We don't currently know these.
                // sizes: "96x96",
                // type: "image/png"
              },
            ]
          : [],
      });
    });

    // @ts-ignore TypeScript does not know about the Media Session API: https://github.com/Microsoft/TypeScript/issues/19473
    navigator.mediaSession.setActionHandler("play", () => {
      webamp.play();
    });
    // @ts-ignore TypeScript does not know about the Media Session API: https://github.com/Microsoft/TypeScript/issues/19473
    navigator.mediaSession.setActionHandler("pause", () => {
      webamp.pause();
    });
    // @ts-ignore TypeScript does not know about the Media Session API: https://github.com/Microsoft/TypeScript/issues/19473
    navigator.mediaSession.setActionHandler("seekbackward", () => {
      webamp.seekBackward(10);
    });
    // @ts-ignore TypeScript does not know about the Media Session API: https://github.com/Microsoft/TypeScript/issues/19473
    navigator.mediaSession.setActionHandler("seekforward", () => {
      webamp.seekForward(10);
    });
    // @ts-ignore TypeScript does not know about the Media Session API: https://github.com/Microsoft/TypeScript/issues/19473
    navigator.mediaSession.setActionHandler("previoustrack", () => {
      webamp.previousTrack();
    });
    // @ts-ignore TypeScript does not know about the Media Session API: https://github.com/Microsoft/TypeScript/issues/19473
    navigator.mediaSession.setActionHandler("nexttrack", () => {
      webamp.nextTrack();
    });
  }
}
