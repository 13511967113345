// @ts-ignore
import osaka from "url:../skins/Azumanga osaka.wsz";
// @ts-ignore
import lucky_star from "url:../skins/CR2JPN_Lucky_Star_Konata.wsz";
// @ts-ignore
import dj_john from "url:../skins/DJ_John_Spider_Man.wsz";
// @ts-ignore
import dj_soundcheck from "url:../skins/DJSoundcheck1.wsz";
// @ts-ignore
import idt_hardcore from "url:../skins/ID-T_HARDCORE.wsz";
// @ts-ignore
import mac_marijuana from "url:../skins/Mac_Marijuana_2001.wsz";
// @ts-ignore
import our_friend_joe from "url:../skins/Our_Friend_Joe.wsz";
// @ts-ignore
import pioneer_deh_mp3 from "url:../skins/PioneerDEH-MP3_v2.22b_.wsz";
// @ts-ignore
import pururin from "url:../skins/Pururin_welcome_to_the_nhk.wsz";
// @ts-ignore
import sonic_dj_amp from "url:../skins/Sonic_DJ_AMp.wsz";
// @ts-ignore
import yotsuba from "url:../skins/Yotsuba&! - Yotsuba Koiwai.wsz";

export default [
  { url: osaka, name: "Azumanga osaka" },
  { url: lucky_star, name: "Lucky Star Konata" },
  { url: dj_john, name: "DJ John Spiderman" },
  { url: dj_soundcheck, name: "DJ soundcheck" },
  { url: idt_hardcore, name: "ID-T HARDCORE" },
  { url: mac_marijuana, name: "Mac Marijuana 2001 " },
  { url: our_friend_joe, name: "Our Friend Joe" },
  { url: pioneer_deh_mp3, name: "Pioneer DEH-MP3" },
  { url: pururin, name: "Pururin welcome to the NHK" },
  { url: sonic_dj_amp, name: "Sonic DJ Amp" },
  { url: yotsuba, name: "Yotsuba! - Yotsuba Koiwai" },
];
